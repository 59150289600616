@tailwind base;
@tailwind components;
@tailwind utilities;

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

[class^="bg-"],
[class*=" bg-"] {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.bg-hero-p2p {
  background-image: url("/public/images/p2p.png");
}

.bg-hero-otc {
  background-image: url("/public/images/otc.png");
}

.bg-hero-api {
  background-image: url("/public/images/api.png");
}

.bg-hero-mask {
  background-image: url("/public/images/mask.svg");
}

.bg-hero-mask-blue {
  background-image: url("/public/images/mask-blue.svg");
  background-position: center bottom;
}

.bg-hero-xend {
  background-image: url("/public/images/xend.svg");
}

.bg-hero-xend-sm {
  background-image: url("/public/images/xend-sm.svg");
}

.bg-hero-footer {
  background-image: url("/public/images/footer.png");
  background-position: top center;
  background-size: cover;
}

.bg-hero-individual {
  background-image: url("/public/images/hero-individual.svg");
  background-position: center right;
  background-size: contain;
  height: 600px;
  width: 100%;
}

.bg-hero-business {
  background-image: url("/public/images/hero-business.svg");
  background-position: bottom left;
  width: 100%;
  background-size: 100%;
}
.cardBoxShadow {
  background: #ffffff;
  border: 0.872902px solid #ebebeb;
  box-shadow: 0px 40.1535px 27.9329px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.arrowLine {
  width: 490px;
}
.arrowLineBox {
  right: 0.7rem;
  top: 0.8rem;
}

@media (min-width: 1524px) {
  .arrowLine {
    width: 720px;
  }
  .arrowLineBox {
    right: -4.8rem;
    top: 0.1rem;
  }
}

@media (max-width: 780px) {
  .bg-hero-footer-sm {
    background-image: url("/public/images/footer-sm.svg");
  }

  .bg-hero-mask-sm {
    background-image: url("/public/images/mask-sm.svg");
  }

  .bg-hero-mask-blue-sm {
    background-image: url("/public/images/mask-blue-sm.svg");
    background-position: bottom bottom;
    background-size: contain;
    height: 980px;
  }

  .bg-hero-individual {
    background: none;
  }

  .bg-hero-business {
    background-image: url("/public/images/hero-business-sm.svg");
    background-position: bottom left;
    width: 100%;
    background-size: 100%;
  }

  .arrowLineBox {
    display: none;
  }
}
